import { ShaderMaterial } from 'three'
import vertexShader from './output.vert'
import fragmentShader from './output.frag'
import { FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass.js'
import { Stage } from './Stage'

export class Output {
	private readonly fsQuad: FullScreenQuad
	private readonly material: ShaderMaterial

	constructor(private readonly stage: Stage) {
		this.fsQuad = new FullScreenQuad()
		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			depthWrite: false,
			uniforms: {
				uTexture: { value: stage.fbo.texture }
			}
		})
		this.fsQuad.material = this.material
	}

	update() {
		this.fsQuad.render(this.stage.renderer)
	}
}
