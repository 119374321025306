#define GLSLIFY 1
uniform sampler2D uTextures[4];
uniform float uOpacity;

varying float vRandom1;
varying float vDistance;
varying vec2 vUv;

void main() {
    float opacity = mix(0.3, 0.6, vRandom1);

    float amount = vDistance;
    float w0 = amount > 0.5 ? 0.0 : 1.0 - amount * 2.0;
    float w1 = amount > 0.5 ? 1.0 - (amount - 0.5) * 2.0 : amount * 2.0;
    float w2 = amount < 0.5 ? 0.0 : (amount - 0.5) * 2.0;

    float alpha = texture2D(uTextures[0], vUv).r * w0 + texture2D(uTextures[1], vUv).r * w1 + texture2D(uTextures[2], vUv).r * w2;
    vec3 color = texture2D(uTextures[3], vec2(vRandom1,0.0)).rgb;

    gl_FragColor = LinearTosRGB(vec4(color, alpha * opacity * uOpacity));
}