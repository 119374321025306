import { Controller } from './App'
import styles from './Cards.module.css'
import { delay } from './utils/delay'
import { Easing, Tween } from '@tweenjs/tween.js'

class Card {
	private number: HTMLElement
	private tween?: Tween<{ elapsed: number }>
	private value: number

	constructor(
		private readonly node: HTMLElement,
		private readonly index: number
	) {
		this.number = node.querySelector(`.${styles.Number}`) as HTMLElement
		this.value = parseFloat(this.number.textContent || '0')
	}

	async show() {
		this.tween?.stop()
		await delay(this.index * 250)
		this.node.classList.add(styles.Visible)
		this.tween = new Tween({ elapsed: 0 })
			.to({ elapsed: 1 }, 1500)
			.easing(Easing.Cubic.Out)
			.onUpdate(({ elapsed }) => {
				this.number.textContent = `${Math.round(this.value * elapsed)}`
			})
			.start()
	}

	hide() {
		this.node.classList.remove(styles.Visible)
	}
}

export class CardsController implements Controller {
	private readonly cards: Card[]
	private tracked = false

	constructor(private readonly node: HTMLElement) {
		this.cards = Array.from(node.querySelectorAll(`.${styles.Card}`) as NodeListOf<HTMLElement>).map(
			(node, index) => new Card(node, index)
		)
	}

	show() {
		const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
			if (entry.isIntersecting) {
				this.cards.forEach((card) => card.show())
				if (!this.tracked) {
					this.tracked = true
					window.dataLayer?.push('scroll', 'cards')
				}
			} else {
				this.cards.forEach((card) => card.hide())
			}
		})
		observer.observe(this.node)
	}
}
