import { MathUtils, Spherical, Vector3 } from 'three'
import { Stage } from './Stage'
import { Spring } from './Spring'
import { CONFIG } from './config'

const SPRING = [50, 20, 1]

export class Dolly {
	private readonly spherical = new Spherical()
	private readonly pointerTheta: Spring
	private readonly pointerPhi: Spring
	private readonly theta: Spring
	private readonly phi: Spring
	private readonly radius: Spring
	private readonly roll: Spring
	private readonly offsetX: Spring
	private readonly offsetY: Spring
	private readonly target = new Vector3()

	constructor(private readonly stage: Stage) {
		const { theta, phi, radius, roll, offsetX, offsetY } = CONFIG[0]
		this.pointerTheta = new Spring(0, ...SPRING)
		this.pointerPhi = new Spring(0, ...SPRING)
		this.theta = new Spring(theta, ...SPRING)
		this.phi = new Spring(phi, ...SPRING)
		this.radius = new Spring(radius, ...SPRING)
		this.roll = new Spring(roll, ...SPRING)
		this.offsetX = new Spring(offsetX, ...SPRING)
		this.offsetY = new Spring(offsetY, ...SPRING)
	}

	update(delta: number): void {
		const {
			pointer: { pointer },
			width,
			height,
			elapsedIndex,
			elapsedFraction
		} = this.stage

		this.pointerTheta.update(delta)
		this.pointerPhi.update(delta)
		this.theta.update(delta)
		this.phi.update(delta)
		this.radius.update(delta)
		this.roll.update(delta)
		this.offsetX.update(delta)
		this.offsetY.update(delta)

		const from = CONFIG[elapsedIndex]
		const to = CONFIG[elapsedIndex + 1] || CONFIG[elapsedIndex]

		this.stage.camera.zoom = this.stage.landscape
			? 1
			: MathUtils.lerp(from.mobileZoom, to.mobileZoom, elapsedFraction)

		this.theta.setTarget(MathUtils.lerp(from.theta, to.theta, elapsedFraction))
		this.phi.setTarget(MathUtils.lerp(from.phi, to.phi, elapsedFraction))
		this.radius.setTarget(MathUtils.lerp(from.radius, to.radius, elapsedFraction))
		this.roll.setTarget(MathUtils.lerp(from.roll, to.roll, elapsedFraction))
		this.offsetX.setTarget(MathUtils.lerp(from.offsetX, to.offsetX, elapsedFraction))
		this.offsetY.setTarget(MathUtils.lerp(from.offsetY, to.offsetY, elapsedFraction))

		this.pointerTheta.setTarget(pointer.x * Math.PI * 0.05)
		this.pointerPhi.setTarget(pointer.y * Math.PI * 0.05)

		this.spherical.theta = this.theta.value + this.pointerTheta.value
		this.spherical.phi = this.phi.value + this.pointerPhi.value
		this.spherical.radius = this.radius.value

		this.stage.camera.position.setFromSpherical(this.spherical)

		this.stage.camera.setViewOffset(
			width,
			height,
			this.offsetX.value * width,
			this.offsetY.value * height,
			width,
			height
		)

		this.stage.camera.lookAt(this.target)

		this.stage.camera.rotation.z = this.roll.value
	}
}
