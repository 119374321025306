const phi = Math.PI * (Math.sqrt(5) - 1)

export function fibonacci(index: number, count: number) {
	const y = 1 - (index / (count - 1)) * 2
	const radius = Math.sqrt(1 - y * y)

	const theta = phi * index

	const x = Math.cos(theta) * radius
	const z = Math.sin(theta) * radius

	return { x, y, z }
}
