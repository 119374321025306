import {
	Clock,
	HalfFloatType,
	LinearFilter,
	NearestFilter,
	Object3D,
	PerspectiveCamera,
	RGBAFormat,
	Scene,
	SRGBColorSpace,
	TextureLoader,
	WebGLRenderer,
	WebGLRenderTarget
} from 'three'
import particleMap from '../assets/particle.png'
import particleBlurMap from '../assets/particle-blur.png'
import particleLargeBlurMap from '../assets/particle-large-blur.png'
import colorMap from '../assets/color.png'
import { Simulation } from './Simulation'
import { Pointer } from './Pointer'
import { Dolly } from './Dolly'
import { Group } from '@tweenjs/tween.js'
import { Maps } from './Maps'
import Background from './Background'
import { Floaters } from './Floaters'
import { Distribution } from './Distribution'
import { Output } from './Output'
import { SquidController } from './SquidController'
import { Cluster } from './Cluster'
import { Lines } from './Lines'
import { Particles } from './Particles'
import { Cube } from './Cube'
import { System } from './System'

export enum COLORS {
	blue = 0x5956ff,
	darkBlue = 0x06051e,
	lightBlue = 0x8583ff,
	white = 0xeaeaff,
	black = 0x00000f
}

export const WIDTH = 128
export const COUNT = WIDTH * WIDTH
export const SIDE = Math.round(Math.pow(COUNT, 1 / 3))

export class Stage {
	public readonly dolly: Dolly

	public readonly background: Background
	public readonly pointer: Pointer
	public readonly simulation: Simulation
	public readonly maps: Maps
	public readonly distribution: Distribution
	public readonly cluster: Cluster
	public readonly lines: Lines
	public readonly particles: Particles
	public readonly cube: Cube
	public readonly system: System

	public readonly renderer: WebGLRenderer
	public readonly scene = new Scene()
	public readonly camera = new PerspectiveCamera()
	public readonly clock = new Clock()

	private readonly floaters: Floaters
	private readonly output: Output
	private readonly object = new Object3D()

	public landscape = false

	public aspect = 1
	public vFov = 1
	public hFov = 1
	public width = 0
	public height = 0
	public fbo: WebGLRenderTarget
	private group = new Group()

	constructor(
		public readonly container: HTMLElement,
		private readonly controller: SquidController
	) {
		this.update = this.update.bind(this)

		this.scene.add(this.object)
		this.object.renderOrder = 1000

		this.renderer = new WebGLRenderer({
			powerPreference: 'high-performance',
			antialias: false,
			stencil: false,
			depth: false
		})
		this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))
		this.container.appendChild(this.renderer.domElement)

		this.camera.near = 1
		this.camera.far = 50
		this.scene.add(this.camera)

		this.fbo = new WebGLRenderTarget(this.width, this.height, {
			type: HalfFloatType,
			format: RGBAFormat,
			minFilter: LinearFilter,
			magFilter: NearestFilter
		})

		this.background = new Background(this)
		this.dolly = new Dolly(this)
		this.floaters = new Floaters(this)
		this.cluster = new Cluster(this)
		this.pointer = new Pointer(this)
		this.maps = new Maps(this)
		this.cube = new Cube(this)
		this.simulation = new Simulation(this)
		this.lines = new Lines(this)
		this.system = new System(this)
		this.distribution = new Distribution(this)
		this.output = new Output(this)
		this.particles = new Particles(this)
	}

	async load() {
		const [particleTexture, particleBlurTexture, particleLargeBlurTexture, colorMapTexture] = await Promise.all([
			new TextureLoader().loadAsync(particleMap),
			new TextureLoader().loadAsync(particleBlurMap),
			new TextureLoader().loadAsync(particleLargeBlurMap),
			new TextureLoader().loadAsync(colorMap)
		])
		colorMapTexture.colorSpace = SRGBColorSpace

		this.particles.setTextures(particleTexture, particleBlurTexture, particleLargeBlurTexture, colorMapTexture)
		this.floaters.setTextures(particleTexture, particleBlurTexture, particleLargeBlurTexture, colorMapTexture)
		this.distribution.setTexture(colorMapTexture)
		this.system.setTexture(colorMapTexture)
	}

	resize() {
		const { width, height } = this.container.getBoundingClientRect()
		this.width = width
		this.height = height
		this.landscape = width > height
		this.aspect = width / height

		this.aspect = this.width / this.height
		this.camera.aspect = this.aspect
		this.camera.updateProjectionMatrix()

		this.vFov = this.camera.getFilmHeight() / this.camera.getFocalLength()
		this.hFov = this.vFov * this.camera.aspect

		this.background.resize()

		const dpr = this.renderer.getPixelRatio()
		this.renderer.setSize(width, height)
		this.fbo.setSize(width * dpr, height * dpr)
	}

	get elapsed() {
		return this.controller.elapsed
	}

	get elapsedIndex() {
		return this.controller.elapsedIndex
	}

	get elapsedFraction() {
		return this.controller.elapsedFraction
	}

	update(time: number) {
		const { inView } = this.controller

		this.group.update(time)
		const delta = Math.min(this.clock.getDelta(), 0.05)
		if (!inView) return

		this.background.update(time)
		this.dolly.update(delta)
		this.pointer.update(time)
		this.cluster.update(time)
		this.maps.update()
		this.cube.update(time)
		this.simulation.update(time, delta)
		this.lines.update(time)
		this.particles.update(time)
		this.floaters.update(time)
		this.distribution.update(time)
		this.system.update(time)

		this.renderer.setRenderTarget(this.fbo)
		this.renderer.render(this.scene, this.camera)

		this.renderer.setRenderTarget(null)
		this.output.update()
	}
}
