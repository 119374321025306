import { NoiseFunction3D } from 'simplex-noise'

export const fbm = (x: number, y: number, z: number, noise: NoiseFunction3D) => {
	let value = 0.0
	let amplitude = 0.5

	for (let i = 0; i < 3; i++) {
		value += amplitude * noise(x, y, z)
		x *= 2
		y *= 2
		amplitude *= 0.5
	}
	return value
}
