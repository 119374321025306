const MAX_DELTA = 0.1
const THRESHOLD = 0.005

export class Spring {
	public target = 0
	public velocity = 0
	public animating = false

	constructor(
		public value = 0,
		public stiffness = 200,
		public damping = 200,
		public mass = 60
	) {
		this.target = this.value
	}

	setTarget(target: number, immediate = false) {
		this.target = target
		if (immediate) {
			this.value = target
			this.velocity = 0
		}
		this.animating = this.target !== this.value
	}

	update(delta: number) {
		if (delta > MAX_DELTA) {
			delta = MAX_DELTA
		}

		const displacement = this.value - this.target
		const springForce = -displacement * this.stiffness
		const dampingForce = -this.velocity * this.damping

		const force = springForce + dampingForce
		this.velocity += (force / this.mass) * delta
		this.value += this.velocity * delta

		this.animating = Math.abs(this.velocity) > THRESHOLD
	}
}
