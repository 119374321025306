import { COUNT, SIDE } from './Stage'
import { Box3, MathUtils, Vector3 } from 'three'
import { toHalf } from './toHalf'

export function stripMap() {
	const array = new Uint16Array(4 * COUNT)
	const box = new Box3()
	const height = COUNT / SIDE / SIDE

	const randomCount = Math.floor(COUNT * 0.05)
	for (let i = 0; i < COUNT; i++) {
		let x = (i % SIDE) / SIDE - 0.5
		// let y = Math.min(Math.floor(i / SIDE) / SIDE - height * 0.25, height * 0.25)
		let y = Math.floor(i / SIDE) / SIDE - height * 0.25
		let z = 0

		if (x < box.min.x) box.min.x = x
		if (y < box.min.y) box.min.y = y
		if (z < box.min.z) box.min.z = z

		if (x > box.max.x) box.max.x = x
		if (y > box.max.y) box.max.y = y
		if (z > box.max.z) box.max.z = z

		if (i > COUNT - randomCount) {
			x = MathUtils.randFloat(-1, 1) * 5
			y = MathUtils.randFloat(-1, 1) * 5
			z = MathUtils.randFloat(-1, 1) * 5
		}

		const index = i * 4
		array[index] = toHalf(x)
		array[index + 1] = toHalf(y)
		array[index + 2] = toHalf(z)
		array[index + 3] = toHalf(i > COUNT - randomCount ? 0.25 : 0.5)
		// array[index + 3] = index / COUNT
	}

	// randomly shuffle some points
	const randomShuffleCount = Math.floor(COUNT * 0.15)
	for (let i = 0; i < randomShuffleCount; i++) {
		const index1 = Math.floor(Math.random() * COUNT)
		const index2 = Math.floor(Math.random() * COUNT)

		const stride1 = index1 * 4
		const stride2 = index2 * 4

		const temp1 = array[stride1]
		const temp2 = array[stride1 + 1]
		const temp3 = array[stride1 + 2]
		const temp4 = array[stride1 + 3]

		array[stride1] = array[stride2]
		array[stride1 + 1] = array[stride2 + 1]
		array[stride1 + 2] = array[stride2 + 2]
		array[stride1 + 3] = array[stride2 + 3]

		array[stride2] = temp1
		array[stride2 + 1] = temp2
		array[stride2 + 2] = temp3
		array[stride2 + 3] = temp4
	}

	const size = box.getSize(new Vector3())
	const center = box.getCenter(new Vector3())

	return { array, size, center }
}
