#define GLSLIFY 1
uniform vec3 uColor;
uniform float uOpacity;

varying float vOpacity;
varying vec3 vBarycentric;

float edgeFactor(){
    vec3 d = fwidth(vBarycentric);
    vec3 a3 = smoothstep(vec3(0.0), d * 3.0, vBarycentric);
    return min(min(a3.x, a3.y), a3.z);
}

void main() {
    float alpha = 1.0 - edgeFactor();
    gl_FragColor = LinearTosRGB(vec4(uColor, alpha * vOpacity * uOpacity * 0.75));
//    gl_FragColor.a *= vOpacity;
}