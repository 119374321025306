import { COUNT, WIDTH } from './Stage'
import { shuffleArray } from '../utils/shuffleArray'
import { BufferAttribute, BufferGeometry } from 'three'

export function lineGeometry() {
	const MODIFIER = Math.sqrt(WIDTH) * 8
	const mods = Math.floor(COUNT / MODIFIER)
	const position = new Float32Array(COUNT * 3).fill(0)
	const indices = new Float32Array(COUNT).map((num, index) => (index % mods) * MODIFIER)
	shuffleArray(indices)
	const nextIndices = new Float32Array(COUNT)
	const prevIndices = new Float32Array(COUNT)

	for (let i = 0; i < COUNT; i++) {
		if (i === 0) {
			prevIndices[i] = indices.at(-1) || 0
			nextIndices[i] = indices[1]
		} else if (i === COUNT - 1) {
			prevIndices[i] = indices[i - 1]
			nextIndices[i] = indices[0]
		} else {
			prevIndices[i] = indices[i - 1]
			nextIndices[i] = indices[i + 1]
		}
	}

	const geometry = new BufferGeometry()
	geometry.setAttribute('position', new BufferAttribute(position, 3))
	geometry.setAttribute('index', new BufferAttribute(indices, 1))
	geometry.setAttribute('prevIndex', new BufferAttribute(prevIndices, 1))
	geometry.setAttribute('nextIndex', new BufferAttribute(nextIndices, 1))

	return geometry
}
