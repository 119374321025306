import { MathUtils, Vector3 } from 'three'
import { Stage } from './Stage'
import { Tween } from '@tweenjs/tween.js'

export class Cluster {
	private readonly target = new Vector3()
	// private readonly center = new Vector3()
	// private readonly group = new Group()
	private readonly transform = {
		opacity: 0
	}

	private visible = false
	private fading = false
	private loop = false

	private start = 0
	private timer = 0

	public readonly position = new Vector3()
	public opacity = 0

	constructor(private readonly stage: Stage) {
		this.fadeOut = this.fadeOut.bind(this)
	}

	show() {
		const {
			maps: { size }
		} = this.stage

		window.clearTimeout(this.timer)
		this.visible = true

		const x = this.loop ? MathUtils.randFloat(-0.25, 0.25) : 0
		const y = this.loop ? MathUtils.randFloat(-0.15, 0.15) : 0
		this.target.set(x * size.x, y * size.y, 0)

		if (this.loop) {
			this.stage.simulation.setCluster(this.target)
		}

		new Tween(this.transform).to({ opacity: 1 }, 150).start()
	}

	hide() {
		this.fading = true
		window.clearTimeout(this.timer)
		this.timer = window.setTimeout(this.fadeOut, 500)
		new Tween(this.transform).to({ opacity: 0 }, 250).start()
	}

	fadeOut() {
		this.visible = false
		this.fading = false
	}

	update(time: number) {
		const { elapsed, elapsedIndex, elapsedFraction } = this.stage

		this.opacity = this.transform.opacity
		this.position.copy(this.target)

		if (this.fading) {
			return
		}

		if (!this.visible && elapsedIndex === 1 && elapsedFraction === 0) {
			this.loop = true
			this.start = time
			this.show()
		}

		if (!this.visible && elapsedIndex === 1 && elapsedFraction > 0) {
			this.loop = false
			this.start = time
			this.show()
		}

		const duration = time - this.start

		if (this.visible && elapsedFraction === 0 && duration > 4000) {
			this.hide()
		}

		if (this.visible && !this.loop && elapsedFraction === 0) {
			this.hide()
		}

		if (this.visible && this.loop && elapsedFraction > 0) {
			this.hide()
		}

		if (this.visible && elapsed > 2) {
			this.hide()
		}
	}
}
