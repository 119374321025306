import { COUNT } from './Stage'
import { Box3, Vector3 } from 'three'
import { fibonacci } from '../utils/fibonacci'
import { fbm } from '../utils/fbm'
import { NoiseFunction3D } from 'simplex-noise'
import { toHalf } from './toHalf'

export function introMap(noise: NoiseFunction3D) {
	const array = new Uint16Array(4 * COUNT)
	const temp1 = new Vector3()
	const box = new Box3()

	for (let i = 0; i < COUNT; i++) {
		const { x, y, z } = fibonacci(i, COUNT)
		temp1.set(x, y, z)
		temp1.multiplyScalar(2)

		const fbmValue = fbm(temp1.x, temp1.y, temp1.z, noise)
		temp1.multiplyScalar(1.0 + fbmValue * 0.5)

		temp1.set(x, y, z)
		temp1.multiplyScalar(1)
		temp1.multiplyScalar(1.0 + fbmValue * 0.5)

		if (Math.random() < 0.05) {
			temp1.multiplyScalar(0.5 + Math.random() * 1.5)
		}

		if (temp1.x < box.min.x) box.min.x = temp1.x
		if (temp1.y < box.min.y) box.min.y = temp1.y
		if (temp1.z < box.min.z) box.min.z = temp1.z

		if (temp1.x > box.max.x) box.max.x = temp1.x
		if (temp1.y > box.max.y) box.max.y = temp1.y
		if (temp1.z > box.max.z) box.max.z = temp1.z

		const index = i * 4
		array[index] = toHalf(temp1.x)
		array[index + 1] = toHalf(temp1.y)
		array[index + 2] = toHalf(temp1.z)
		array[index + 3] = toHalf(Math.random() > 0.1 ? 0 : 0.5)
	}

	const size = box.getSize(new Vector3())
	const center = box.getCenter(new Vector3())

	return { array, size, center }
}
