import {
	InstancedBufferAttribute,
	InstancedMesh,
	MathUtils,
	Matrix4,
	PlaneGeometry,
	ShaderMaterial,
	Texture
} from 'three'
import vertexShader from './floaters.vert'
import fragmentShader from './floaters.frag'
import { Stage } from './Stage'
import { CONFIG } from './config'

const SIDE = 10
const SPACE = 3
const COUNT = SIDE * SIDE * SIDE

export class Floaters {
	private readonly material: ShaderMaterial
	private readonly mesh: InstancedMesh

	constructor(private readonly stage: Stage) {
		const random1 = new Float32Array(COUNT)
		const random2 = new Float32Array(COUNT)
		const translate = new Float32Array(COUNT * 3)
		let index = 0
		for (let i = 0; i < SIDE; i++) {
			for (let ii = 0; ii < SIDE; ii++) {
				for (let iii = 0; iii < SIDE; iii++) {
					random1[index] = Math.random()
					random2[index] = Math.random()

					const x = i * SPACE - (SIDE - 1) * SPACE * 0.5
					const y = ii * SPACE - (SIDE - 1) * SPACE * 0.5
					const z = iii * SPACE - (SIDE - 1) * SPACE * 0.5

					translate[index * 3] = x + MathUtils.randFloat(-1, 1) * 2
					translate[index * 3 + 1] = y + MathUtils.randFloat(-1, 1) * 2
					translate[index * 3 + 2] = z + MathUtils.randFloat(-1, 1) * 2

					index++
				}
			}
		}

		const geometry = new PlaneGeometry()
		geometry.setAttribute('translate', new InstancedBufferAttribute(translate, 3))
		geometry.setAttribute('random1', new InstancedBufferAttribute(random1, 1))
		geometry.setAttribute('random2', new InstancedBufferAttribute(random2, 1))

		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			transparent: true,
			depthTest: false,
			uniforms: {
				uOpacity: { value: 0 },
				uViewMatrix: { value: new Matrix4() },
				uTime: { value: performance.now() },
				uAperture: { value: 3 },
				uScale: { value: 1 },
				uTextures: {
					value: [null, null, null, null]
				}
			}
		})

		this.mesh = new InstancedMesh(geometry, this.material, COUNT)
		this.mesh.frustumCulled = false
		this.stage.scene.add(this.mesh)
	}

	setTextures(
		particleTexture: Texture,
		particleBlurTexture: Texture,
		particleLargeBlurTexture: Texture,
		colorMapTexture: Texture
	) {
		this.material.uniforms.uTextures.value[0] = particleTexture
		this.material.uniforms.uTextures.value[1] = particleBlurTexture
		this.material.uniforms.uTextures.value[2] = particleLargeBlurTexture
		this.material.uniforms.uTextures.value[3] = colorMapTexture
	}

	update(time: number) {
		const { elapsedIndex, elapsedFraction, camera, elapsed } = this.stage

		const from = CONFIG[elapsedIndex]
		const to = CONFIG[elapsedIndex + 1] || CONFIG[elapsedIndex]

		this.material.uniforms.uAperture.value = MathUtils.lerp(from.aperture, to.aperture, elapsedFraction)
		this.material.uniforms.uOpacity.value = MathUtils.clamp(elapsed, 0, 1)
		this.material.uniforms.uViewMatrix.value = camera.matrixWorldInverse
		this.material.uniforms.uTime.value = time
	}
}
