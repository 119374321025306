export function grid(distance: number, width: number) {
	let x
	let y
	if (distance < width) {
		x = distance
		y = 0
	} else if (distance < width * 2) {
		x = width
		y = distance - width
	} else if (distance < width * 3) {
		x = width - (distance - width * 2)
		y = width
	} else {
		x = 0
		y = width - (distance - width * 3)
	}

	return { x, y }
}
