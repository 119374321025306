import { COUNT, WIDTH } from './Stage'
import { NoiseFunction3D } from 'simplex-noise'
import { Box3, Vector3 } from 'three'
import { grid } from '../utils/grid'
import { fbm } from '../utils/fbm'
import { toHalf } from './toHalf'

export function closeMap(noiseFunc: NoiseFunction3D) {
	const temp1 = new Vector3()
	const temp2 = new Vector3()
	const core = new Vector3()
	const box = new Box3()

	const array = new Uint16Array(4 * COUNT)
	array[0] = toHalf(0)
	array[1] = toHalf(0)
	array[2] = toHalf(0)
	array[3] = toHalf(0)

	const gap = 0.05
	const inner = 0.3
	const z = 0
	let side = 3
	let index = 1

	while (index < COUNT) {
		const count = side * side - (side - 2) * (side - 2)
		const width = side - 1
		const offset = side * 0.5

		for (let i = 0; i < count; i++) {
			let { x, y } = grid(i, width)

			x -= offset - 0.5
			y -= offset - 0.5

			x *= gap
			y *= gap

			temp1.set(x, y, z)
			temp1.multiplyScalar(0.25)

			const noise = noiseFunc(temp1.x, temp1.y, temp1.z)
			const noiseValue = (noise + 1) * 0.5

			temp1.multiplyScalar(4)
			const fbmValue = fbm(temp1.x, temp1.y, temp1.z, noiseFunc)
			const angle = noiseValue * 2 * Math.PI

			temp1.set(x, y, z)

			temp2.set(fbmValue, fbmValue, fbmValue)
			temp2.multiplyScalar(0.5)
			temp1.add(temp2)

			temp2.set(Math.cos(angle), Math.sin(angle), noise * 2)
			temp2.multiplyScalar(1.5)
			temp1.add(temp2)

			temp2.randomDirection().multiplyScalar(Math.random() * 0.25)
			temp1.add(temp2)

			const dist = temp1.distanceTo(core)

			if (index < COUNT) {
				const outer = dist > WIDTH * gap * inner
				if (outer) {
					temp2.set(0, 1, 0).multiplyScalar(2 + Math.random() * 2)
					temp1.add(temp2)
				}

				if (temp1.x < box.min.x) box.min.x = temp1.x
				if (temp1.y < box.min.y) box.min.y = temp1.y
				if (temp1.z < box.min.z) box.min.z = temp1.z

				if (temp1.x > box.max.x) box.max.x = temp1.x
				if (temp1.y > box.max.y) box.max.y = temp1.y
				if (temp1.z > box.max.z) box.max.z = temp1.z

				const stride = index * 4
				array[stride] = toHalf(temp1.x)
				array[stride + 1] = toHalf(temp1.y)
				array[stride + 2] = toHalf(temp1.z)
				array[stride + 3] = toHalf(outer ? Math.random() * 0.25 : 0.5)
				index++
			} else {
				break
			}
		}

		side += 2
	}

	const size = box.getSize(new Vector3())
	const center = box.getCenter(new Vector3())

	return { array, size, center }
}
