import { COUNT, SIDE } from './Stage'
import { Box3, MathUtils, Vector3 } from 'three'
import { toHalf } from './toHalf'

export function cubeMap() {
	const array = new Uint16Array(4 * COUNT)
	const box = new Box3()
	const cubeCount = SIDE * SIDE * SIDE

	for (let i = 0; i < COUNT; i++) {
		let x, y, z

		x = (i % SIDE) / SIDE - 0.5
		y = Math.floor(i / (SIDE * SIDE)) / SIDE - 0.5
		z = (Math.floor(i / SIDE) % SIDE) / SIDE - 0.5

		if (x < box.min.x) box.min.x = x
		if (y < box.min.y) box.min.y = y
		if (z < box.min.z) box.min.z = z

		if (x > box.max.x) box.max.x = x
		if (y > box.max.y) box.max.y = y
		if (z > box.max.z) box.max.z = z

		if (i >= cubeCount) {
			x = MathUtils.randFloat(-1, 1) * SIDE * 0.2
			y = MathUtils.randFloat(-1, 1) * SIDE * 0.2
			z = MathUtils.randFloat(-1, 1) * SIDE * 0.2
		}

		const stride = i * 4
		array[stride] = toHalf(x)
		array[stride + 1] = toHalf(y)
		array[stride + 2] = toHalf(z)
		array[stride + 3] = toHalf(i >= cubeCount ? 0 : 0.25)
	}

	const size = box.getSize(new Vector3())
	const center = box.getCenter(new Vector3())

	return { array, size, center }
}
