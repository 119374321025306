#define GLSLIFY 1
uniform vec3 uColor;
uniform float uOpacity;

varying float vOpacity;
varying float vNoise;

void main() {
    float alpha = vOpacity * vNoise * 0.35;
    gl_FragColor = LinearTosRGB(vec4(uColor, alpha * uOpacity));
    if(alpha < 0.001) discard;
}