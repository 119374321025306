.Main {
	position: fixed;
	background-color: white;
	padding: 10px;
	align-items: center;
	display: none;
	bottom: var(--padding);
	left: var(--padding);
	right: var(--padding);
	grid-template-columns: minmax(0, 1fr) min-content min-content;
	z-index: 99999;
}

.Visible {
	display: grid;
}

.Text {
	justify-self: start;
	padding: 0 1.5em;
	font-variation-settings:
		'wght' 700,
		'wdth' 125;
}

.Button {
	cursor: pointer;
	padding: 0.8em 1.2em;
	font-family: 'Archivo Italic', sans-serif;
	font-size: clamp(0.75rem, 0.6863rem + 0.2614vw, 1rem);
	gap: 0.5em;
	font-weight: 700;
	border-radius: 2em;
	text-decoration: none;
	box-sizing: border-box;
	color: white;
	background-color: black;
	transition:
		opacity 250ms linear,
		transform 250ms var(--ease-in-out-circ);
}

.Button:hover {
	opacity: 0.8;
}

.Button:active {
	transform: scale(0.95);
}

@media (min-width: 400px) {
	.Main {
		left: auto;
		right: 5vmin;
		bottom: 5vmin;
	}
}
