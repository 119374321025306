import styles from './Squid.module.css'
import { Stage } from './Stage'
import { Panel } from './Panel'
import { MathUtils } from 'three'
import { Easing, Tween } from '@tweenjs/tween.js'
import { delay } from '../utils/delay'
import { Nav } from './Nav'
import { Controller } from '../App'

export class SquidController implements Controller {
	private readonly stage: Stage
	private readonly panels: Panel[]
	private readonly sections: HTMLElement[]
	private readonly numSections: number
	private readonly nav: Nav

	private sectionPositions: number[] = []
	private needsScrollUpdate = false
	private introElapsed = 0
	private timer = 0

	public inView = false
	public elapsed = 0
	public elapsedIndex = 0
	public elapsedFraction = 0
	public height = 0

	constructor(private readonly node: HTMLElement) {
		this.scrollEnd = this.scrollEnd.bind(this)

		this.sections = Array.from(node.querySelectorAll(`.${styles.Section}`) as NodeListOf<HTMLElement>)
		this.numSections = this.sections.length

		this.stage = new Stage(node.querySelector(`.${styles.Stage}`) as HTMLElement, this)
		this.nav = new Nav(node.querySelector(`.${styles.Nav}`) as HTMLElement, this)

		this.panels = Array.from(node.querySelectorAll(`.${styles.Panel}`) as NodeListOf<HTMLElement>).map(
			(panel, index) => new Panel(panel, this.stage, index + 1, index + 1 === this.numSections)
		)
	}

	async load() {
		await this.stage.load()
	}

	async show() {
		await delay(250)
		await this.animateIntro()
		this.introElapsed = 1
	}

	async animateIntro() {
		// this.elapsed = 1
		return new Promise((resolve) => {
			new Tween({ elapsed: 0 })
				.easing(Easing.Exponential.InOut)
				.to({ elapsed: 1 }, 1500)
				.onUpdate(({ elapsed }) => {
					this.needsScrollUpdate = true
					this.elapsed = elapsed
				})
				.onComplete(resolve)
				.start()
		})
	}

	scrollTo(index: number) {
		window.scrollTo({ top: this.sectionPositions[index], behavior: 'smooth' })
	}

	scrollEnd() {
		if (this.introElapsed < 1 || this.elapsed >= 4) {
			return
		}
		const fraction = this.elapsed % 1
		const target = fraction > 0.25 ? Math.ceil(this.elapsed) - 1 : Math.floor(this.elapsed) - 1
		this.scrollTo(target)
	}

	scroll() {
		window.clearTimeout(this.timer)

		const scrollY = window.scrollY
		let index = 0

		for (let i = 0; i < this.sectionPositions.length; i++) {
			const top = this.sectionPositions[i] - scrollY
			if (top >= 0) {
				break
			}
			index = i
		}

		const clampedIndex = Math.min(index, this.numSections - 2)
		const diff = this.sectionPositions[clampedIndex + 1] - this.sectionPositions[clampedIndex]
		const elapsedIndex = Math.min(clampedIndex + this.introElapsed, this.numSections - 1)
		const elapsedFraction = (scrollY - this.sectionPositions[clampedIndex]) / diff
		this.elapsed = MathUtils.clamp(elapsedIndex + elapsedFraction, 0, this.numSections)

		this.inView = window.scrollY <= this.height
		if (this.inView) {
			this.timer = window.setTimeout(this.scrollEnd, 1000)
		}

		this.needsScrollUpdate = true
	}

	resize() {
		const scrollY = window.scrollY
		const { height } = this.node.getBoundingClientRect()
		this.height = height
		this.stage.resize()
		this.panels.forEach((panel) => panel.resize())
		this.sectionPositions = this.sections.map((section) => {
			return scrollY + (section.getBoundingClientRect().top || 0)
		})
	}

	update(time: number) {
		this.stage.update(time)

		this.elapsedIndex = Math.floor(this.elapsed)
		this.elapsedFraction = this.elapsed === this.numSections ? 1 : Math.max(this.elapsed - this.elapsedIndex)

		if (this.needsScrollUpdate) {
			this.needsScrollUpdate = false
			this.panels.forEach((panel) => panel.update())
			this.nav.update()
		}
	}
}
