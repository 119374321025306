import { Controller } from './App'
import styles from './Benefits.module.css'

export class BenefitsController implements Controller {
	constructor(private readonly node: HTMLElement) {}

	show() {
		const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
			entry.isIntersecting ? this.node.classList.add(styles.Visible) : this.node.classList.remove(styles.Visible)
		})
		observer.observe(this.node)
	}
}
