import styles from './Cookie.module.css'
import cookie from 'js-cookie'

export class CookieController {
	constructor() {
		const node = document.querySelector(`.${styles.Main}`) as HTMLElement
		const accept = node.querySelector(`.${styles.Button}`) as HTMLElement
		const consent = cookie.get('cookie-consent')

		if (consent === 'accepted') {
			//noop
		} else {
			node.classList.add(styles.Visible)

			accept.addEventListener('click', () => {
				cookie.set('cookie-consent', 'accepted', { expires: 365 })
				node.classList.remove(styles.Visible)
			})
		}
	}
}
