import { Stage } from './Stage'
import { MathUtils, Mesh, PlaneGeometry, ShaderMaterial, Texture, Vector3 } from 'three'
import vertexShader from './distribution.vert'
import fragmentShader from './distribution.frag'
import { Easing, Group, Tween } from '@tweenjs/tween.js'
import BezierEasing from 'bezier-easing'
import { delay } from '../utils/delay'

const easing1 = BezierEasing(1, 0, 0.75, -0.25)
const easing2 = BezierEasing(0.135, 1.35, 0.35, 1)

export class Distribution {
	private readonly target = new Vector3()
	private readonly center = new Vector3()
	private readonly group = new Group()
	private readonly plane: Mesh
	private readonly material: ShaderMaterial

	private direction = true
	private running = false

	public readonly position = new Vector3()
	public opacity = 1
	public transform = { opacity: 1 }

	constructor(private readonly stage: Stage) {
		this.animate = this.animate.bind(this)
		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			transparent: true,
			uniforms: {
				uTime: { value: 0 },
				uElapsed: { value: 0 },
				uOpacity: { value: 0 },
				uColor: { value: null }
			}
		})
		this.plane = new Mesh(new PlaneGeometry(), this.material)
		this.plane.scale.set(12, 12, 12)
		this.stage.scene.add(this.plane)
	}

	setTexture(texture: Texture) {
		this.material.uniforms.uColor.value = texture
	}

	async animate() {
		this.direction = Math.random() > 0.5

		const t1 = new Tween(this.target, this.group)
			.to({ x: this.direction ? -20 : 0, y: 0, z: this.direction ? 0 : -20 }, 1000)
			.easing(easing1)

		const t2 = new Tween(this.transform, this.group)
			.delay(850)
			.to({ opacity: 0 }, 150)
			.onComplete(() => (this.transform.opacity = 0))

		const t3 = new Tween(this.material.uniforms.uElapsed, this.group)
			.to({ value: 1 }, 2000)
			.easing(Easing.Cubic.Out)
			.onStart(() => {
				const x = this.direction ? -10 : 0
				const z = this.direction ? 0 : -10
				const rotY = this.direction ? Math.PI * 0.5 : 0

				this.plane.rotation.set(0, rotY, 0)
				this.plane.position.set(x, 0, z)
				this.material.uniforms.uOpacity.value = 1
				this.stage.system.hide(this.direction)
			})

		const t4 = new Tween(this.material.uniforms.uOpacity, this.group).to({ value: 0 }, 1000).onComplete(() => {
			this.material.uniforms.uElapsed.value = 0
			this.target.set(0, 2.5, 0)
			this.stage.system.show(this.direction)
		})

		const t5 = new Tween(this.target, this.group)
			.to({ x: 0, y: 0, z: 0 }, 750)
			.easing(easing2)
			.onStart(() => {
				this.stage.simulation.reset()
				this.transform.opacity = 1
			})
			.onComplete(() => {
				this.animate()
			})

		t1.chain(t3)
		t3.chain(t4)
		t4.chain(t5)

		await delay(2000)

		if (this.running) {
			t1.start()
			t2.start()
		}
	}

	update(time: number) {
		this.group.update(time)

		const { elapsedIndex, elapsedFraction } = this.stage

		this.material.uniforms.uTime.value = time

		if (!this.running && elapsedIndex === 4) {
			this.group.removeAll()
			this.running = true
			this.transform.opacity = 1
			this.animate()
		}

		if (this.running && elapsedIndex < 4) {
			this.running = false
			this.material.uniforms.uOpacity.value = 0
			this.material.uniforms.uElapsed.value = 0
			this.group.removeAll()
			this.target.set(0, 0, 0)
		}

		const elapsed = elapsedIndex >= 3 ? MathUtils.clamp((elapsedFraction - 0.75) * 4, 0, 1) : 0
		this.position.copy(elapsedFraction < 1 ? this.center : this.target)
		this.opacity = elapsedFraction < 1 ? 1 : this.transform.opacity

		this.position.x += Math.cos(time * 0.001) * 0.1 * elapsed
		this.position.y += Math.sin(time * 0.001) * 0.1 * elapsed
		this.position.z += Math.cos(time * 0.001) * 0.1 * elapsed
	}
}
