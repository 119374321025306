import {
	GLSL3,
	HalfFloatType,
	LinearFilter,
	MathUtils,
	Mesh,
	NearestFilter,
	OrthographicCamera,
	PlaneGeometry,
	RGBAFormat,
	Scene,
	ShaderMaterial,
	Texture,
	Vector3,
	WebGLRenderTarget
} from 'three'
import vertexShader from './simulation.vert'
import fragmentShader from './simulation.frag'
import { Stage, WIDTH } from './Stage'
import { CONFIG } from './config'

export class Simulation {
	private readonly uniforms: {
		uTextures: { value: (null | Texture)[] }
		uReset: { value: boolean }
		uPointerStrength: { value: number }
		uCenterY: { value: number }
		uDelta: { value: number }
		uElapsedIndex: { value: number }
		uPointerVelocity: { value: Vector3 }
		uTargetStrength: { value: number }
		uPointer: { value: Vector3 }
		uHeight: { value: number }
		uTime: { value: number }
		uElapsedFraction: { value: number }
		uCluster: { value: Vector3 }
		uClusterStrength: { value: number }
		uNoise: { value: number }
	}
	private readonly camera: OrthographicCamera
	private readonly scene: Scene
	private readonly mesh: Mesh

	private tik = true

	public readonly fbo1: WebGLRenderTarget
	public readonly fbo2: WebGLRenderTarget

	private needsReset = [false, false]

	constructor(private readonly stage: Stage) {
		this.scene = new Scene()
		this.camera = new OrthographicCamera()

		this.fbo1 = new WebGLRenderTarget(WIDTH, WIDTH, {
			type: HalfFloatType,
			format: RGBAFormat,
			minFilter: LinearFilter,
			magFilter: NearestFilter,
			count: 2
		})

		this.fbo2 = new WebGLRenderTarget(WIDTH, WIDTH, {
			type: HalfFloatType,
			format: RGBAFormat,
			minFilter: LinearFilter,
			magFilter: NearestFilter,
			count: 2
		})

		this.uniforms = {
			uTextures: {
				value: [
					this.fbo1.textures[1],
					this.fbo1.textures[1],
					this.stage.maps.intro,
					this.stage.maps.universe,
					this.stage.maps.close,
					this.stage.maps.strip,
					this.stage.maps.cube
				]
			},
			uTargetStrength: { value: 1 },
			uPointerStrength: { value: 1 },
			uReset: { value: false },
			uCenterY: { value: 0 },
			uHeight: { value: 0 },
			uDelta: { value: 1 / 60 },
			uTime: { value: 0 },
			uElapsedFraction: { value: 0 },
			uElapsedIndex: { value: 0 },
			uPointer: { value: new Vector3() },
			uPointerVelocity: { value: new Vector3() },
			uCluster: { value: new Vector3() },
			uClusterStrength: { value: 0 },
			uNoise: { value: 0 }
		}

		const material = new ShaderMaterial({
			glslVersion: GLSL3,
			vertexShader,
			fragmentShader,
			uniforms: this.uniforms
		})

		this.mesh = new Mesh(new PlaneGeometry(2, 2), material)
		this.scene.add(this.mesh)

		// debugTexture(this.fbo1.textures[0], this.stage.scene)
	}

	setCluster(pos: Vector3) {
		this.uniforms.uCluster.value.copy(pos)
		this.uniforms.uClusterStrength.value = 1
	}

	reset() {
		this.uniforms.uTargetStrength.value = 1
		this.uniforms.uClusterStrength.value = 0
		this.needsReset = [true, true]
	}

	update(time: number, delta: number) {
		const {
			elapsedIndex,
			elapsedFraction,
			pointer: { local, velocity },
			maps: { size, center }
		} = this.stage

		const from = CONFIG[elapsedIndex]
		const to = CONFIG[elapsedIndex + 1] || CONFIG[elapsedIndex]
		const noise = MathUtils.lerp(from.noise, to.noise, elapsedFraction)
		const pointerStrength = MathUtils.lerp(from.pointerStrength, to.pointerStrength, elapsedFraction)

		this.tik = !this.tik

		this.uniforms.uCenterY.value = center.y
		this.uniforms.uHeight.value = size.y
		this.uniforms.uTime.value = time
		this.uniforms.uDelta.value = delta
		this.uniforms.uElapsedFraction.value = elapsedFraction
		this.uniforms.uElapsedIndex.value = elapsedIndex
		this.uniforms.uPointer.value.copy(local)
		this.uniforms.uPointerVelocity.value.copy(velocity)
		this.uniforms.uPointerStrength.value = pointerStrength
		this.uniforms.uClusterStrength.value = MathUtils.clamp(this.uniforms.uClusterStrength.value - 0.01, 0, 1)
		this.uniforms.uNoise.value = noise

		if ((this.tik && this.needsReset[0]) || (!this.tik && this.needsReset[1])) {
			this.tik ? (this.needsReset[0] = false) : (this.needsReset[1] = false)
			this.uniforms.uReset.value = true
		} else {
			this.uniforms.uReset.value = false
		}

		this.mesh.visible = true
		this.stage.renderer.setRenderTarget(this.tik ? this.fbo1 : this.fbo2)
		this.stage.renderer.render(this.scene, this.camera)

		this.uniforms.uTextures.value[0] = this.tik ? this.fbo1.textures[0] : this.fbo2.textures[0]
		this.uniforms.uTextures.value[1] = this.tik ? this.fbo1.textures[1] : this.fbo2.textures[1]
	}
}
