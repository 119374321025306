import { Controller } from './App'

export class CallToActionController implements Controller {
	private tracked = false
	constructor(private readonly node: HTMLElement) {}

	show() {
		const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
			if (entry.isIntersecting) {
				if (!this.tracked) {
					this.tracked = true
					window.dataLayer?.push({ scroll: 'call-to-action' })
				}
				observer.disconnect()
			}
		})
		observer.observe(this.node)
	}
}
