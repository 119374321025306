import { Vector2, Vector3 } from 'three'
import { Stage } from './Stage'

export class Pointer {
	private readonly lastPoint = new Vector3()
	private lastTime = 0

	public readonly pointer = new Vector2()
	public readonly mouse = new Vector3()
	public readonly world = new Vector3()
	public readonly local = new Vector3()
	public readonly size = new Vector2()
	public readonly velocity = new Vector3()

	constructor(private readonly stage: Stage) {
		window.addEventListener('mousemove', this.onMove.bind(this))
		this.lastTime = performance.now()
	}

	onMove(event: MouseEvent) {
		const { x, y } = event
		this.pointer.set((x / this.stage.width) * 2 - 1, -(y / this.stage.height) * 2 + 1)
		this.mouse.set(this.pointer.x, this.pointer.y, 0.5)
		this.mouse.unproject(this.stage.camera)
		this.mouse.sub(this.stage.camera.position).normalize()
		const distance = -this.stage.camera.position.z / this.mouse.z
		this.world.copy(this.stage.camera.position).add(this.mouse.multiplyScalar(distance))
	}

	update(time: number) {
		this.local.copy(this.world)

		const duration = time - this.lastTime
		if (duration > 100) {
			this.velocity.copy(this.world).sub(this.lastPoint).divideScalar(duration).multiplyScalar(100)
			this.lastPoint.copy(this.world)
			this.lastTime = time
		}
	}
}
