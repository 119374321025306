import { DataTexture, HalfFloatType, RGBAFormat, Vector3 } from 'three'
import { createNoise3D } from 'simplex-noise'
import { Stage, WIDTH } from './Stage'
import { introMap } from './introMap'
import { universeMap } from './universeMap'
import { closeMap } from './closeMap'
import { stripMap } from './stripMap'
import { cubeMap } from './cubeMap'

const noise3D = createNoise3D()

export class Maps {
	public readonly intro: DataTexture
	public readonly universe: DataTexture
	public readonly close: DataTexture
	public readonly strip: DataTexture
	public readonly cube: DataTexture

	private sizes: Vector3[] = []
	private centers: Vector3[] = []
	public size = new Vector3()
	public center = new Vector3()

	constructor(private readonly stage: Stage) {
		const { array: intro, size: introSize, center: introCenter } = introMap(noise3D)
		this.intro = new DataTexture(intro, WIDTH, WIDTH, RGBAFormat, HalfFloatType)
		this.intro.needsUpdate = true
		this.sizes[0] = introSize
		this.centers[0] = introCenter

		const { array: universe, size: universeSize, center: universeCenter } = universeMap(noise3D)
		this.universe = new DataTexture(universe, WIDTH, WIDTH, RGBAFormat, HalfFloatType)
		this.universe.needsUpdate = true
		this.sizes[1] = universeSize
		this.centers[1] = universeCenter

		const { array: close, size: closeSize, center: closeCenter } = closeMap(noise3D)
		this.close = new DataTexture(close, WIDTH, WIDTH, RGBAFormat, HalfFloatType)
		this.close.needsUpdate = true
		this.sizes[2] = closeSize
		this.centers[2] = closeCenter

		const { array: strip, size: stripSize, center: stripCenter } = stripMap()
		this.strip = new DataTexture(strip, WIDTH, WIDTH, RGBAFormat, HalfFloatType)
		this.strip.needsUpdate = true
		this.sizes[3] = stripSize
		this.centers[3] = stripCenter

		const { array: cube, size: cubeSize, center: cubeCenter } = cubeMap()
		this.cube = new DataTexture(cube, WIDTH, WIDTH, RGBAFormat, HalfFloatType)
		this.cube.needsUpdate = true
		this.sizes[4] = cubeSize
		this.centers[4] = cubeCenter
	}

	update() {
		const { elapsedIndex, elapsedFraction } = this.stage
		this.size.lerpVectors(
			this.sizes[elapsedIndex],
			this.sizes[elapsedIndex + 1] || this.sizes[elapsedIndex],
			elapsedFraction
		)
		this.center.lerpVectors(
			this.centers[elapsedIndex],
			this.centers[elapsedIndex + 1] || this.centers[elapsedIndex],
			elapsedFraction
		)
	}
}
