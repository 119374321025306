#define GLSLIFY 1
uniform sampler2D uColor;
uniform sampler2D uParticle;
uniform sampler2D uParticleBlur;
uniform sampler2D uParticleLargeBlur;
uniform vec3 uWhite;
uniform float uOpacity;

varying float vWhite;
varying float vScale;
varying float vRandom1;
varying float vRandom2;
varying float vDistance;
varying vec2 vUv;

void main() {
    float opacity = max(mix(0.85, 1.0, vRandom2), vWhite);

    float amount = vDistance;
    float w0 = amount > 0.5 ? 0.0 : 1.0 - amount * 2.0;
    float w1 = amount > 0.5 ? 1.0 - (amount - 0.5) * 2.0 : amount * 2.0;
    float w2 = amount < 0.5 ? 0.0 : (amount - 0.5) * 2.0;

    float alpha = texture2D(uParticle, vUv).r * w0 + texture2D(uParticleBlur, vUv).r * w1 + texture2D(uParticleLargeBlur, vUv).r * w2;
    vec3 color = texture2D(uColor, vec2(vRandom1,0.0)).rgb;

    vec3 c = mix(color, uWhite, vWhite);
    float a = clamp(alpha * opacity * uOpacity, 0.0, 1.0);

    gl_FragColor = LinearTosRGB(vec4(c, a));
}