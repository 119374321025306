#define GLSLIFY 1
uniform sampler2D uPosition;
uniform mat4 uViewMatrix;
uniform vec3 uPointer;
uniform vec3 uCluster;
uniform float uScale;
uniform float uFocus;
uniform float uAperture;
uniform float uTime;
uniform float uClusterOpacity;
uniform float uCenterCluster;
uniform float uCenterOpacity;

attribute vec2 reference;
attribute float random1;
attribute float random2;

varying float vWhite;
varying float vScale;
varying float vRandom1;
varying float vRandom2;
varying float vDistance;
varying vec2 vUv;

mat4 lerpMat4(mat4 a, mat4 b, float t) {
    return mat4(
    mix(a[0], b[0], t),
    mix(a[1], b[1], t),
    mix(a[2], b[2], t),
    mix(a[3], b[3], t)
    );
}

void main() {
    vec4 value = texture2D(uPosition, reference);

    float scale = mix(0.05, 0.2, random1) * uScale * value.a;

    mat4 matrix = uViewMatrix;
    vec4 pos = vec4(position * scale, 1.0) * matrix;
    pos.xyz += value.xyz;

    vec4 mvPos = modelViewMatrix * pos;
    gl_Position = projectionMatrix * mvPos;

    float randomWhite = smoothstep(0.9995, 1.0, (sin(uTime * 0.0001 + random1 * 5000.0) + 1.0) * 0.5);
    float clusterWhite = (1.0 - smoothstep(2.0, 2.2, length(pos.xyz - uCluster))) * uClusterOpacity;

    vWhite = min(randomWhite + clusterWhite, 1.0);

    vUv = uv;
    vUv *= vec2(0.5,0.5);
    vUv.x += step(random1, 0.5) * 0.5;
    vUv.y += step(random2, 0.5) * 0.5;

    vRandom1 = random1;
    vRandom2 = random2;
    vScale = value.a;

    vDistance = clamp((abs(mvPos.z) - uFocus)/uAperture, 0.0, 1.0);
}