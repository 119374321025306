#define GLSLIFY 1
uniform sampler2D uTexture;

varying vec2 vUv;

float sdCircle(vec2 p, float r) {
	return length(p) - r;
}

float random(vec2 p) {
	vec2 K1 = vec2(
		23.14069263277926, // e^pi (Gelfond's constant)
		2.665144142690225 // 2^sqrt(2) (Gelfond–Schneider constant)
	);
	return fract(cos(dot(p, K1)) * 12345.6789);
}

vec3 black = vec3(0.0);

void main() {
	vec4 color = texture2D(uTexture, vUv);

	vec2 uvRandom = vUv;
	uvRandom.y *= random(vec2(uvRandom.y));
	color.rgb += random(uvRandom) * 0.05;

	gl_FragColor = vec4(color.rgb, 1.0);
//	gl_FragColor = vec4(1.0, vUv, 1.0);
}
