import { Color, InstancedMesh, MathUtils, Matrix4, ShaderMaterial, Texture, Vector3 } from 'three'
import vertexShader from './particles.vert'
import fragmentShader from './particles.frag'
import { COLORS, COUNT, Stage } from './Stage'
import { particleGeometry } from './particleGeometry'
import { CONFIG } from './config'

export class Particles {
	private readonly mesh: InstancedMesh
	private readonly uniforms: {
		uScroll: { value: number }
		uClusterOpacity: { value: number }
		uAperture: { value: number }
		uPointer: { value: Vector3 }
		uViewMatrix: { value: Matrix4 }
		uOpacity: { value: number }
		uScale: { value: number }
		uCenterCluster: { value: number }
		uWhite: { value: Color }
		uFocus: { value: number }
		uCluster: { value: Vector3 }
		uTime: { value: number }
		uColor: { value: Texture | null }
		uParticle: { value: Texture | null }
		uParticleBlur: { value: Texture | null }
		uParticleLargeBlur: { value: Texture | null }
		uPosition: { value: Texture }
	}

	constructor(private readonly stage: Stage) {
		this.uniforms = {
			uViewMatrix: { value: new Matrix4() },
			uPointer: { value: new Vector3() },
			uCluster: { value: new Vector3() },
			uClusterOpacity: { value: 0 },
			uCenterCluster: { value: 0 },
			uWhite: { value: new Color(COLORS.white) },
			uTime: { value: performance.now() },
			uScroll: { value: 0 },
			uScale: { value: 1 },
			uFocus: { value: 0 },
			uAperture: { value: CONFIG[0].aperture },
			uOpacity: { value: 1 },
			uColor: { value: null },
			uParticle: { value: null },
			uParticleBlur: { value: null },
			uParticleLargeBlur: { value: null },
			uPosition: { value: this.stage.simulation.fbo1.textures[1] }
		}

		const material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			transparent: true,
			depthTest: false,
			uniforms: this.uniforms
		})

		this.mesh = new InstancedMesh(particleGeometry(), material, COUNT)
		this.mesh.frustumCulled = false
		this.stage.scene.add(this.mesh)
	}

	setTextures(
		particleTexture: Texture,
		particleBlurTexture: Texture,
		particleLargeBlurTexture: Texture,
		colorMapTexture: Texture
	) {
		this.uniforms.uParticle.value = particleTexture
		this.uniforms.uParticleBlur.value = particleBlurTexture
		this.uniforms.uParticleLargeBlur.value = particleLargeBlurTexture
		this.uniforms.uColor.value = colorMapTexture
	}

	update(time: number) {
		const { elapsedIndex, elapsedFraction, pointer, camera, distribution, cluster } = this.stage
		const from = CONFIG[elapsedIndex]
		const to = CONFIG[elapsedIndex + 1] || CONFIG[elapsedIndex]
		const radius = MathUtils.lerp(from.radius, to.radius, elapsedFraction)
		const aperture = MathUtils.lerp(from.aperture, to.aperture, elapsedFraction)

		this.mesh.position.copy(distribution.position)

		this.uniforms.uPointer.value.copy(pointer.world)
		this.uniforms.uViewMatrix.value = camera.matrixWorldInverse
		this.uniforms.uAperture.value = aperture
		this.uniforms.uFocus.value = radius
		this.uniforms.uScale.value = 1
		this.uniforms.uTime.value = time
		this.uniforms.uCluster.value.copy(cluster.position)
		this.uniforms.uClusterOpacity.value = cluster.opacity
		this.uniforms.uOpacity.value = distribution.opacity
	}
}
