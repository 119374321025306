import { Stage } from './Stage'
import { MathUtils, Mesh, Object3D, PlaneGeometry, ShaderMaterial, Texture } from 'three'
import vertexShader from './system.vert'
import fragmentShader from './system.frag'
import { Group, Tween } from '@tweenjs/tween.js'

export class System {
	private readonly left: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly right: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly group = new Group()
	private readonly object = new Object3D()

	constructor(private readonly stage: Stage) {
		this.left = new Mesh(
			new PlaneGeometry(),
			new ShaderMaterial({
				vertexShader,
				fragmentShader,
				transparent: true,
				uniforms: {
					uTime: { value: 0 },
					uOpacity: { value: 0 },
					uAlpha: { value: 1 },
					uColor: { value: null }
				}
			})
		)
		this.left.scale.set(7, 7, 7)
		this.left.position.set(-10, 0, 0)
		this.left.rotation.set(0, Math.PI * 0.5, 0)

		this.right = new Mesh(
			new PlaneGeometry(),
			new ShaderMaterial({
				vertexShader,
				fragmentShader,
				transparent: true,
				uniforms: {
					uTime: { value: 0 },
					uOpacity: { value: 0 },
					uAlpha: { value: 1 },
					uColor: { value: null }
				}
			})
		)
		this.right.scale.set(7, 7, 7)
		this.right.position.set(0, 0, -10)

		this.object.add(this.left)
		this.object.add(this.right)

		this.stage.scene.add(this.object)
	}

	hide(direction: boolean) {
		direction ? (this.left.visible = false) : (this.right.visible = false)
	}

	show(direction: boolean) {
		const side = direction ? this.left : this.right
		side.visible = true
		side.material.uniforms.uAlpha.value = 0
		new Tween({ alpha: 0 }, this.group)
			.to({ alpha: 1 }, 250)
			.onUpdate(({ alpha }) => (side.material.uniforms.uAlpha.value = alpha))
			.start()
	}

	setTexture(texture: Texture) {
		this.left.material.uniforms.uColor.value = texture
		this.right.material.uniforms.uColor.value = texture
	}

	update(time: number) {
		this.group.update(time)
		const { elapsedIndex, elapsedFraction } = this.stage

		if (elapsedIndex < 3) {
			this.object.visible = false
			this.group.removeAll()
		} else {
			const opacity = MathUtils.clamp((elapsedFraction - 0.5) / 0.5, 0, 1)
			this.object.visible = true
			this.left.material.uniforms.uTime.value = time
			this.right.material.uniforms.uTime.value = time
			this.left.material.uniforms.uOpacity.value = opacity
			this.right.material.uniforms.uOpacity.value = opacity
		}
	}
}
