import { SquidController } from './SquidController'

export default class Tracking {
	private readonly triggered: boolean[]
	private elapsedIndex = -1

	constructor(
		private readonly labels: string[],
		private readonly controller: SquidController
	) {
		this.triggered = labels.map((_) => false)
	}

	update() {
		const index = this.controller.elapsedIndex - 1

		if (index >= 0 && this.elapsedIndex !== index && !this.triggered[index]) {
			this.elapsedIndex = index
			this.triggered[this.elapsedIndex] = true
			window.dataLayer?.push({ scroll: this.labels[this.elapsedIndex] })
		}
	}
}
