import { AdditiveBlending, Color, LineSegments, MathUtils, ShaderMaterial, Texture, Vector3 } from 'three'
import vertexShader from './lines.vert'
import fragmentShader from './lines.frag'
import { COLORS, Stage, WIDTH } from './Stage'
import { lineGeometry } from './lineGeometry'
import { CONFIG } from './config'

export class Lines {
	private readonly mesh: LineSegments
	private readonly uniforms: {
		uTextures: { value: Texture[] }
		uClusterOpacity: { value: number }
		uCenterCluster: { value: number }
		uWidth: { value: number }
		uWhite: { value: Color }
		uColor: { value: Color }
		uCluster: { value: Vector3 }
		uDistance: { value: number }
		uOverwrite: { value: number }
		uOpacity: { value: number }
		uTime: { value: number }
	}

	constructor(private readonly stage: Stage) {
		this.uniforms = {
			uCluster: { value: new Vector3() },
			uClusterOpacity: { value: 0 },
			uCenterCluster: { value: 0 },
			uTime: { value: 0 },
			uWhite: { value: new Color(COLORS.white) },
			uWidth: { value: WIDTH },
			uDistance: { value: 1.5 },
			uColor: { value: new Color(COLORS.lightBlue) },
			uOverwrite: { value: 0 },
			uOpacity: { value: 0 },
			uTextures: {
				value: [this.stage.simulation.fbo1.textures[1]]
			}
		}

		const material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			blending: AdditiveBlending,
			depthTest: false,
			transparent: true,
			uniforms: this.uniforms
		})

		this.mesh = new LineSegments(lineGeometry(), material)
		this.mesh.frustumCulled = false
		this.stage.scene.add(this.mesh)
	}

	update(time: number) {
		const { elapsedIndex, elapsedFraction, cluster, distribution } = this.stage
		const from = CONFIG[elapsedIndex]
		const to = CONFIG[elapsedIndex + 1] || CONFIG[elapsedIndex]
		const lineDistance = MathUtils.lerp(from.lineDistance, to.lineDistance, elapsedFraction)

		this.mesh.position.copy(distribution.position)

		this.uniforms.uTime.value = time
		this.uniforms.uDistance.value = lineDistance
		this.uniforms.uCluster.value.copy(cluster.position)
		this.uniforms.uClusterOpacity.value = cluster.opacity
		this.uniforms.uOverwrite.value = 1
		this.uniforms.uOpacity.value = distribution.opacity
	}
}
