import { COUNT, WIDTH } from './Stage'
import { InstancedBufferAttribute, PlaneGeometry } from 'three'

export function particleGeometry() {
	const random1 = new Float32Array(COUNT).map(() => Math.random())
	const random2 = new Float32Array(COUNT).map(() => Math.random())

	const references = new Float32Array(COUNT * 2)
	for (let i = 0; i < COUNT; i++) {
		const index = i * 2
		const x = (i % WIDTH) / WIDTH
		const y = Math.floor(i / WIDTH) / WIDTH
		references[index] = x
		references[index + 1] = y
	}

	const geometry = new PlaneGeometry(1, 1)
	geometry.setAttribute('reference', new InstancedBufferAttribute(references, 2, true, 1))
	geometry.setAttribute('random1', new InstancedBufferAttribute(random1, 1, true, 1))
	geometry.setAttribute('random2', new InstancedBufferAttribute(random2, 1, true, 1))

	return geometry
}
