import { Controller } from './App'
import styles from './Benefits.module.css'

export class BenefitsController implements Controller {
	private tracked = false
	constructor(private readonly node: HTMLElement) {}

	show() {
		const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
			if (entry.isIntersecting) {
				this.node.classList.add(styles.Visible)
				if (!this.tracked) {
					this.tracked = true
					window.dataLayer?.push({ scroll: 'benefits' })
				}
			} else {
				this.node.classList.remove(styles.Visible)
			}
		})
		observer.observe(this.node)
	}
}
