export const CONFIG = [
	{
		theta: Math.PI * 0.25,
		phi: Math.PI * 0.25,
		radius: 8,
		roll: 0,
		offsetX: 0,
		offsetY: 0,
		aperture: 0.25,
		lineDistance: 0,
		noise: 0.25,
		pointerStrength: 0,
		mobileZoom: 1
	},
	{
		theta: 0,
		phi: Math.PI * 0.5,
		radius: 14,
		roll: 0,
		offsetX: 0,
		offsetY: 0,
		aperture: 1,
		lineDistance: 3,
		noise: 1,
		pointerStrength: 1,
		mobileZoom: 0.5
	},
	{
		theta: 0,
		phi: Math.PI * 0.5,
		radius: 5,
		roll: 0,
		offsetX: 0.2,
		offsetY: 0,
		aperture: 2,
		lineDistance: 2,
		noise: 0.25,
		pointerStrength: 1,
		mobileZoom: 0.75
	},
	{
		theta: 0,
		phi: Math.PI * 0.5,
		radius: 5,
		roll: 0,
		offsetX: -0.1,
		offsetY: 0.5,
		aperture: 2,
		lineDistance: 1.5,
		noise: 0,
		pointerStrength: 0.5,
		mobileZoom: 0.75
	},
	{
		theta: 0.75,
		phi: 1.25,
		radius: 3,
		roll: 0.3,
		offsetX: 0,
		offsetY: 0,
		aperture: 1,
		lineDistance: 0.5,
		noise: 0,
		pointerStrength: 0.1,
		mobileZoom: 0.35
	}
]
