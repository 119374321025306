.Main {
	color: white;
	background-color: black;
}

.Stage {
	position: fixed;
	width: 100vw;
	height: 100lvh;
}

.Section {
	position: relative;
	height: 100lvh;
}

.Content {
	position: fixed;
	width: 100vw;
	height: 100dvh;
	top: 0;
}

.Panel {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: grid;
	align-content: end;
	padding-inline: calc(var(--padding) * 2);
	padding-block-end: 5rem;
	box-sizing: border-box;
	opacity: 0;
	pointer-events: none;
}

.Panel.Visible {
	opacity: 1;
	pointer-events: auto;
	transition: opacity 250ms linear;
}

.Panel.Fade {
	opacity: 0;
}

.Eyebrow {
	overflow: hidden;
	padding: 0 0 0.75em 0;
	font-size: clamp(0.875rem, 0.7157rem + 0.6536vw, 1.5rem);
	font-variation-settings:
		'wght' 600,
		'wdth' 125;
}

.EyebrowInner {
	composes: SlideInDown from '../Animations.module.css';
	animation-delay: 350ms;
	display: block;
}

.Panel.Visible .EyebrowInner :global {
	animation-name: SlideInDown;
}

.Index {
	overflow: hidden;
	font-size: clamp(0.875rem, 0.7794rem + 0.3922vw, 1.25rem);
	font-variation-settings:
		'wght' 600,
		'wdth' 125;
	padding: 0 0 5em 0;
}

.IndexInner {
	composes: SlideInDown from '../Animations.module.css';
	animation-delay: 550ms;
	display: block;
}

.Panel.Visible .IndexInner :global {
	animation-name: SlideInDown;
}

.Title {
	composes: Heading1 from '../Typography.module.css';
	padding: 0 0 0.3em 0;
	display: flex;
	flex-wrap: wrap;
	gap: 0 0.333em;
}

.Word {
	overflow: hidden;
}

.WordInner {
	composes: SlideInDown from '../Animations.module.css';
	--delay: 0;
	display: block;
	animation-delay: var(--delay);
}

.Panel.Visible .WordInner :global {
	animation-name: SlideInDown;
}

.Text {
	composes: SlideFadeInDown from '../Animations.module.css';
	composes: Copy from '../Typography.module.css';
	animation-delay: 500ms;
}

.Panel.Visible .Text :global {
	animation-name: SlideFadeInDown;
}

.Scroll {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.Hint {
	composes: ScaleFadeIn from '../Animations.module.css';
	animation-delay: 750ms;
	transform-origin: center bottom;
	display: grid;
	justify-content: center;
	justify-items: center;
	font-family: 'Archivo Italic', sans-serif;
	font-size: clamp(0.75rem, 0.6863rem + 0.2614vw, 1rem);
	font-weight: 700;
	gap: 1rem;
}

.Panel.Visible .Hint :global {
	animation-name: ScaleFadeIn;
}

.Line {
	width: 1px;
	/*height: 3.125rem;*/
	height: 2rem;
	background-color: currentColor;
}

.Nav {
	position: fixed;
	right: 2.5rem;
	top: 0;
	height: 100lvh;
	align-content: center;
	display: none;
	opacity: 0;
	pointer-events: none;
}

.Nav.Visible {
	opacity: 1;
	pointer-events: auto;
}

.Inner {
	position: relative;
	display: grid;
	gap: 0.375rem;
}

.Button {
	position: relative;
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
}

.Button.Active {
	pointer-events: none;
}

.Dot {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 0.375rem;
	height: 0.375rem;
	border-radius: 50%;
	background-color: white;
}

.Ring {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: solid 1px white;
	box-sizing: border-box;
	opacity: 0;
	transform: scale(0.9);
	transition:
		transform 250ms var(--ease-in-out-cubic),
		opacity 250ms linear;
}

.Indicator {
	--index: 0;
	position: absolute;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 50%;
	border: solid 1px white;
	box-sizing: border-box;
	transform: translateY(calc(var(--index) * 1.625rem));
	transition: transform 500ms var(--ease-in-out-circ);
}

.Button:hover .Ring {
	opacity: 1;
	transform: scale(1);
}

@media (min-width: 768px) {
	.Panel {
		padding-inline: var(--padding);
		padding-block: 20vmin;
	}

	.Text {
		max-width: 50ch;
	}

	.Nav {
		display: grid;
	}
}

@media (min-width: 1024px) {
	.Panel {
		grid-template-columns: 46%;
	}

	.First {
		grid-template-columns: 80%;
	}

	.Even {
		justify-content: end;
	}

	.Odd {
		justify-items: end;
	}

	.Odd .Title {
		justify-content: end;
	}

	.Odd .Text {
		text-align: right;
	}
}

@media (min-width: 1920px) {
	.Panel {
		grid-template-columns: 42.5%;
	}

	.First {
		grid-template-columns: 80%;
	}
}
