import { BoxGeometry, Color, DoubleSide, MathUtils, Mesh, ShaderMaterial } from 'three'
import vertexShader from './cube.vert'
import fragmentShader from './cube.frag'
import { COLORS, Stage } from './Stage'
import { addBarycentricCoordinates } from '../utils/addBarycentricCoordinates'
import { Easing } from '@tweenjs/tween.js'

export class Cube {
	private readonly material: ShaderMaterial
	private readonly mesh: Mesh

	constructor(private readonly stage: Stage) {
		const geometry = new BoxGeometry().toNonIndexed()
		addBarycentricCoordinates(geometry, true)

		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			side: DoubleSide,
			transparent: true,
			depthTest: false,
			uniforms: {
				uTime: { value: performance.now() },
				uColor: { value: new Color(COLORS.blue) },
				uOpacity: { value: 1 }
			}
		})

		this.mesh = new Mesh(geometry, this.material)
		this.stage.scene.add(this.mesh)
	}

	update(time: number) {
		const { elapsedIndex, elapsedFraction, distribution } = this.stage

		this.mesh.visible = elapsedIndex >= 3
		this.mesh.position.copy(this.stage.distribution.position)

		const elapsed = MathUtils.clamp((elapsedFraction - 0.75) * 4, 0, 1)
		const scale = 1 + 0.333 - Easing.Sinusoidal.Out(elapsed) * 0.333
		this.mesh.scale.set(scale, scale, scale)
		this.material.uniforms.uOpacity.value = elapsed * distribution.opacity
		this.material.uniforms.uTime.value = time
	}
}
