import { Color, MathUtils, Mesh, PlaneGeometry, ShaderMaterial } from 'three'

import fragmentShader from './background.frag'
import vertexShader from './background.vert'
import { COLORS, Stage } from './Stage'

export default class Background {
	private readonly material: ShaderMaterial

	constructor(private readonly stage: Stage) {
		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			depthTest: false,
			uniforms: {
				uTime: { value: performance.now() },
				uAspect: { value: 1 },
				uFade: { value: 0 },
				uColor1: { value: new Color(COLORS.darkBlue) },
				uColor2: { value: new Color(COLORS.black) }
			}
		})

		const geometry = new PlaneGeometry(2, 2)

		const mesh = new Mesh(geometry, this.material)
		mesh.renderOrder = -100
		mesh.frustumCulled = false
		stage.scene.add(mesh)
	}

	resize() {
		const { width, height } = this.stage
		this.material.uniforms.uAspect.value = width / height
	}

	update(time: number) {
		const { elapsed } = this.stage
		this.material.uniforms.uFade.value = MathUtils.clamp(elapsed, 0.25, 1)
		this.material.uniforms.uTime.value = time
	}
}
